/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$overlay-bg: rgba(0, 0, 0, 0.8);
$modal-overlay-bg: rgba(255, 255, 255, 0.6);
$overlay-header-bg: $go-darkblue;
$overlay-width: 500px;
$overlay-height: 500px;
$overlay-border-color: $global-border-color;
$overlay-header-txt: #fff;
$spinner-wrapper-height: 200px;

.overlay {
  border:     none;
  position:   absolute;
  z-index:    map_get($zindex, 'modal-overlay');
  transition: $transition;

  @media(max-width: $screen-md-min) {
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    width:  100%;
    height: 100vh;
  }
  @media(min-width: $screen-md) {
    min-width:     $overlay-width;
    transform:     translate(-50%, -50%);
    top:           50%;
    left:          50%;
    border-radius: $global-border-radius;
  }

  @media(min-width: $screen-lg) {
    width: 700px;
  }
}

.small {
  width: 30%;
}

.medium {
  width: 750px;
}

.large {
  width: 1050px;
}

.extra-large-hack-for-EA-profiles {
  width:     90%;
  max-width: 1300px;
}

.overlay-header {
  background:    $overlay-header-bg;
  padding:       15px 20px;
  border-radius: $global-border-radius $global-border-radius 0 0;

  h3 {
    font-size:     16.5px;
    margin:        0;
    color:         $overlay-header-txt;
    font-weight:   500;

    max-width:     calc(100% - 25px);
    overflow:      hidden;
    white-space:   nowrap;
    text-overflow: ellipsis;
  }
}

.overlay-close {
  position:    absolute;
  right:       6px;
  top:         11px;
  background:  transparent;
  border:      none;
  color:       $white;
  font-size:   16px;
  font-weight: 500;
  cursor:      pointer;
}

.close-icon {
  @include icon-before($type: $fa-var-close);
}

.overlay-content {
  background: $white;
  position:   relative;
  padding:    20px 30px;
  overflow-y: auto;
  @media(max-width: $screen-md-min) {
    max-height: calc(100vh - 168px);
  }
  @media(min-width: $screen-md) {
    max-height: $overlay-height;
  }

}

.overlay-footer {
  background:                 $white;
  display:                    flex;
  justify-content:            flex-end;
  padding:                    10px 30px;
  border-top:                 1px solid $overlay-border-color;
  border-bottom-left-radius:  $global-border-radius;
  border-bottom-right-radius: $global-border-radius;

  .button {
    margin: 0;
  }
}

.fixed {
  overflow: hidden;
}

.overlay-bg {
  background: $overlay-bg;
  position:   fixed;
  top:        0;
  left:       0;
  right:      0;
  bottom:     0;
  z-index:    map_get($zindex, 'modal-overlay-bg');
}

.modal-body-overlay {
  $modal_header_height: 51px;
  $modal_footer_height: 55px;
  z-index:    map_get($zindex, 'modal-overlay-bg');
  background: $modal-overlay-bg;
  position:   fixed;
  width:      100%;
  top:        $modal_header_height;
  left:       0;
  right:      0;
  bottom:     $modal_footer_height;
}

.spinner-wrapper {
  min-height: $spinner-wrapper-height;
}

$error-message-margin-bottom: 40px;
.error-wrapper {
  margin-bottom: $error-message-margin-bottom;
}
